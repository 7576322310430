import React, { useState } from "react";
import FormSection from "../Form/FormSection";
import Poster from "../Poster/Poster";
import "../../App.css";
import Anime from "../Anime/Anime";

function Home() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleFormSuccess = () => {
    setFormSubmitted(true);
  };
  return (
    <div className="App">
      <div className="App-container">
        <Poster />
        {formSubmitted ? (
          <Anime />
        ) : (
          <FormSection onSuccess={handleFormSuccess} />
        )}
      </div>
    </div>
  );
}

export default Home;
