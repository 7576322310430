import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home.jsx";
import Sucess from "./components/Sucess/Sucess.jsx";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/evolving_threat_landscapes" element={<Home />} />
          <Route
            path="/evolving_threat_landscapes/sucess"
            element={<Sucess />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
