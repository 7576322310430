import React from "react";
import MailGif from "./sucessMail.gif";
import "./Anime.scss";

function Anime() {
  return (
    <div className="anime">
      <img src={MailGif} alt="Sucess Image" className="anime-img" />

      <h3 className="anime-heading">Thank You for Registering!</h3>
      <div>
        <p className="anime-text">
          We're excited to have you join us at <b>Cybrew 2024</b> and look
          forward to greeting you there!
        </p>
        <p className="anime-text">
          Please check your inbox for a confirmation mail along with your entry
          pass. If you have any questions or need further information, feel free
          to reach out at &nbsp;
          <a href="mailto:events@tsqatar.com">events@tsqatar.com</a>. <br /> See
          you soon!
        </p>
      </div>
    </div>
  );
}

export default Anime;
