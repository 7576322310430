import React, { useState } from "react";
import "./Form.scss";

function FormSection({ onSuccess }) {
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    designation: "",
    company: "",
    contact_no: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "contact_no" ? parseFloat(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const powerAutomateUrl =
      "https://prod-08.westeurope.logic.azure.com:443/workflows/ced04f138bf44a5e911282cb064f4381/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PaJkQQVhWXBSFsv1miZ97fF_WBtip7vZn4kVFK6k1q0";

    try {
      const response = await fetch(powerAutomateUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          fName: "",
          lName: "",
          email: "",
          designation: "",
          company: "",
          contact_no: "",
        });
        onSuccess();
      } else {
        alert("Something went wrong. Please try again");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending data.");
    }
  };

  return (
    <form id="myForm" onSubmit={handleSubmit}>
      <div className="container">
        <div className="form-header">
          <h4>Please fill the following fields</h4>
          <p className="reqFeild">
            <b>*Required field</b>
          </p>
        </div>
        <div className="form">
          <input
            type="text"
            id="name"
            name="fName"
            placeholder="First Name *"
            value={formData.fName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="lName"
            placeholder="Last Name *"
            value={formData.lName}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Business Email *"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="contact_no"
            placeholder="Contact Number *"
            id="contact_no"
            value={formData.contact_no}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="designation"
            placeholder="Designation *"
            id="designation"
            value={formData.designation}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="company"
            placeholder="Company Name *"
            id="company"
            value={formData.company}
            onChange={handleChange}
            required
          />
          <button>Confirm Registration</button>
        </div>
        <div className="footer">
          <p>
            <a href="https://tsqatar.com/">www.tsqatar.com</a>
          </p>
          <div></div>
        </div>
      </div>
    </form>
  );
}

export default FormSection;
