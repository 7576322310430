import React from "react";
import "./Poster.scss";

function Poster() {
  return (
    <div className="poster">
      <div className="poster-bg">
        <img
          src="https://events.tsqatar.com/evolving_threat_landscapes/assets/bg-text.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default Poster;
