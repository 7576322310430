import React from "react";
import Poster from "../Poster/Poster";
import "../../App.css";
import Anime from "../Anime/Anime";

function Home() {
  return (
    <div className= "App">
      <div className="App-container">
        <Poster />
        <Anime />
      </div>
    </div>
  );
}

export default Home;
